@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}

	/* ul,
	ol {
		list-style: revert;
	} */

	img {
		@apply select-none;
	}
}

@font-face {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: 450;
	src: local("Circular Std Book"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Book.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Book Italic";
	font-style: italic;
	font-weight: 450;
	src: local("Circular Std Book Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-BookItalic.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Medium";
	font-style: normal;
	font-weight: 500;
	src: local("Circular Std Medium"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Medium.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Medium Italic";
	font-style: italic;
	font-weight: 500;
	src: local("Circular Std Medium Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-MediumItalic.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Bold";
	font-style: normal;
	font-weight: 700;
	src: local("Circular Std Bold"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Bold.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Bold Italic";
	font-style: italic;
	font-weight: 700;
	src: local("Circular Std Bold Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-BoldItalic.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Black";
	font-style: normal;
	font-weight: 900;
	src: local("Circular Std Black"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Black.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Black Italic";
	font-style: italic;
	font-weight: 900;
	src: local("Circular Std Black Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-BlackItalic.woff")
			format("woff");
}

/* width */
*::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 35px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: #555;
}

* {
	font-family: "Circular Std Book", "Roboto", sans-serif !important;
	/* font-family: "Roboto", sans-serif !important; */
}

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

footer {
	margin-top: auto;
}

#headlessui-portal-root > div > div {
	background: #00000080;
}

.circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(255, 255, 255, 0.2);
	animation: animate 25s linear infinite;
	bottom: -150px;
}
.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.circles li:nth-child(6) {
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.circles li:nth-child(7) {
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.circles li:nth-child(10) {
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}
@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

.month {
	background: red;
	padding: 2px 10px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.day {
	background: white;
	padding: 2px 10px;
	line-height: 1;
}
.year {
	background: white;
	padding: 2px 10px;
	margin-top: -5px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.quill > .ql-container {
	@apply block min-h-[200px] w-full flex-1 resize-none overflow-visible rounded-b-md py-2 placeholder:text-gray-400 focus:outline-none focus:ring-0;
}

.quill > .ql-container > .ql-editor {
	@apply px-3 py-0;
}

.ql-editor a {
	color: #0070f3;
}

.ql-editor a:hover {
	text-decoration: underline;
}

.ql-blank p {
	height: 180px;
}

.overflow-anywhere {
	overflow-wrap: anywhere !important;
}

.bgSquare {
	background-size: 30px 30px;
	background-image: linear-gradient(90deg, #00000015 1px, #0000 0),
		linear-gradient(180deg, #00000015 1px, #0000 0);
	/* url("https://lucdn.letsupgrade.net/trainer_portal_login_bottom_3b656347f3.png"); */
}

.bgSquareDark {
	background-size: 30px 30px;
	background-image: linear-gradient(90deg, #ffffff15 1px, #fff0 0),
		linear-gradient(180deg, #ffffff15 1px, #fff0 0);
	/* url("https://lucdn.letsupgrade.net/trainer_portal_login_bottom_3b656347f3.png"); */
}

.gridLockBlueBeams {
	background-image: url("https://tailwindcss.com/_next/static/media/0.2a25f0af.jpg");
	background-size: 300% 300%;
}
.gridLockRedBeams {
	background-image: url("https://tailwindcss.com/_next/static/media/6.36962145.jpg");
	background-size: 230% 200%;
}

.gridLock {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.linearGradient {
	background-image: linear-gradient(
		90deg,
		rgba(56, 189, 248, 0) 0%,
		#0ea5e9 32.29%,
		rgba(236, 72, 153, 0.3) 67.19%,
		rgba(236, 72, 153, 0) 100%
	);
	background-size: 100% 1px;
	/* background-size: 200% 1px; */
	/* animation: gradientSwing 15s ease infinite; */
}

@keyframes gradientSwing {
	0% {
		background-position: 200% 1px;
	}
	50% {
		background-position: -200% 1px;
	}
	100% {
		background-position: 200% 1px;
	}
}

.gradientFont {
	background: linear-gradient(90.47deg, #ee3b13 15.93%, #ff9650 65.04%);
	background-clip: text;
	color: transparent;
	font-weight: 700;
}

.iti.iti--allow-dropdown {
	@apply relative w-full;
}
.iti__flag-container,
.iti__country-list {
	@apply w-full;
}
.iti__country-name {
	@apply whitespace-pre-wrap;
}

.gradientBorder {
	border-image: linear-gradient(to bottom, #000, #ff6600);
}

.swiper-button-prev::after, .swiper-button-next::after {
	@apply text-lu-500;
}
